//navbar
export const HOME = '/';
export const PORTFOLIO = '/portfolio';
export const BLOG = '/blog';
export const ABOUT_ME = '/about-me';
export const CONTACT = '/contact';
export const RESUME = '/resume';
export const PLAYGROUND = '/playground';

//portfolio
export const BUZZFEED = '/buzzfeed';
export const SEEFOOD = '/seefood';
export const MIT_DCI = '/mit-dci';
export const EQUALS_EQUALS = '/equals-equals';
export const CODE2040 = '/code2040'
export const ARDUINO_IPOD = '/arduino-ipod';

//playground
export const SNAKE = '/snake';
