function Contact() {
    return (
        <div>
            <h1>Contact</h1>
            <div className="app_box-text">
                Feel free to email me at <a href="mailto:tamiolaf@gmail.com">tamiolaf@gmail.com</a>! 
                I'll get back to you as soon as I can.
            </div>
        </div>
    )
}

export default Contact;
