import data from '../../copy/code2040.json';

const Code2040 = () => {
  return (
    <div>
      <h1>{data.title}</h1>
    </div>
  )
}

export default Code2040;
