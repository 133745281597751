import data from '../../copy/arduino-ipod.json'

const ArduinoIpod = () => {
  return (
    <div>
      <h1>{data.title}</h1>
    </div>
  )
}

export default ArduinoIpod;
