import data from '../../copy/mit-dci.json';

const MITDCI = () => {
    return (
        <div>
            <h1>{data.title}</h1>
        </div>
    )
};

export default MITDCI;
