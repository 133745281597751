
function Resume() {
    return (
        <div style={{"height": "80vh"}}>
            <object data="/Olafunmiloye_Resume_July_2024.pdf" type="application/pdf" width="100%" height="100%">
            </object>
        </div>)
}

export default Resume;
